export default {
    namespaced: true,
    state: {
      registrationSurveys: {
        data: [],
        meta: {}
      },
      isFetchingSurveys: false
    },
    mutations: {
      STORE_REGISTRATION_SURVEYS(state, surveys) {
        state.registrationSurveys = surveys;
      },
      SET_IS_FETCHING_SURVEYS(state, value) {
        state.isFetchingSurveys = value;
      }
    }
  };
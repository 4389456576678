<script setup>

</script>

<template>

    <footer id="footer" class="footer">
        <div class="copyright">
            &copy; <strong><span>MyZola Admin</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
            Made with <i class="bi bi-heart-fill text-danger"></i>
        </div>
    </footer>

</template>

<style scoped>

</style>